/* src/styles/main.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  color: #333;
}

header {
  background-color: #4CAF50;
  padding: 10px 0;
  color: white;
  text-align: center;
}

header nav a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

header nav a:hover {
  text-decoration: underline;
}

main {
  padding: 20px;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
}
